$(document).ready(function() {

	/* =====  MOBILE MENU TOGGLE  ====== */
	$('.offCanvasToggler').click(function(event) {
		event.preventDefault();
		$('.off-canvas-nav').toggleClass('active');
		$('.fullscreen-overlay').toggleClass('active');
		$('html, body').toggleClass('no-scrolling');
	});

	$('.btn-merk').click(function(event) {
		const merk = event.currentTarget.dataset.merk;
		event.preventDefault();

		$('.btn-merk').removeClass('active');
		$(this).addClass('active');

		$('.merk-block').removeClass('active');
		$(`.merk-block[data-merk=${merk}]`).addClass('active');
	});

	$('.troeven-grid').isotope({
		itemSelector: '.grid-item',
		percentPosition: true,
		masonry: {
			columnWidth: '.grid-sizer',
			gutter: 30,
			horizontalOrder: true
		}
	});

	$('.blog-grid').imagesLoaded( function(){
		$('.blog-grid').isotope({
			itemSelector: '.grid-item',
			percentPosition: true,
			masonry: {
				columnWidth: '.grid-sizer',
				gutter: 30,
				horizontalOrder: true
			}
		});
	});

	$('.quotes-slider').slick({
		prevArrow: $('#prev-slick'),
		nextArrow: $('#next-slick'),
		arrows: true,
		dots: false
	});

	$('.dropdown-submenu > a').on("click", function(e) {
		var submenu = $(this);
		$('.dropdown-submenu .dropdown-menu').removeClass('show');
		submenu.next('.dropdown-menu').addClass('show');
		e.stopPropagation();
	});

	$('.dropdown').on("hidden.bs.dropdown", function() {
		// hide any open menus when parent closes
		$('.dropdown-menu.show').removeClass('show');
	});

	$('.nav-item.dropdown').hover(function(e) {
	    $(this).addClass('show');
	    $(this).children('.dropdown-menu').addClass('show');
	}, function() {
	    $(this).removeClass('show');
	    $(this).children('.dropdown-menu').removeClass('show');
	});

	// $('.dropdown-submenu > a').on("click", function(e) {
	// 	var submenu = $(this);
	// 	$('.dropdown-submenu .dropdown-menu').removeClass('show');
	// 	submenu.next('.dropdown-menu').addClass('show');
	// 	e.stopPropagation();
	// });

	$('.dropdown-submenu > a').hover(function(e) {
	    var submenu = $(this);
	    $('.dropdown-submenu .dropdown-menu').removeClass('show');
	    submenu.next('.dropdown-menu').addClass('show');
	    e.stopPropagation();
	}, function() {
	    
	});


	$('.verkooppunt-header').click(function(event) {
		event.preventDefault();
		const id = event.currentTarget.dataset.id;
		$(this).toggleClass('active');

		$(`.verkooppunt-content[data-id=${id}]`).slideToggle();
	});
});
